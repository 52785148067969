<template>
  <!--<Layout>-->
  <div class="d-flex flex-column flex-grow-1 h-100">
    <TableDefault @changeheadershow="changeheadershow" :options="config" />
  </div>
  <!--</Layout>-->
</template>

<script>
import _ from 'lodash'
import { onMounted, ref } from 'vue'
import useView from '@/compositions/useView.js'

// import { payment } from '@/pages'
import paymentConfigOrig from '@/pages/payment/index'
import zayavkaConfigOrig from '@/pages/zayavka/index'
import { personalTabs as personalTabsOrig } from '@/pages/personal/index'
import { initPaymentZayavka } from '@/utils/helpers.js'
import tableAccountBankOrig from '@/pages/account/config/table-account-bank'
import formAccountEditOrig from '@/pages/account/config/form-account-edit'
import formPaymentEditOrig from '@/pages/payment/config/form-add-edit'

export default {
  name: 'Payment-View',

  components: {
    //Layout,
  },
  methods: {
    changeheadershow(options) {
      const { headerEl, value } = options
      headerEl.isShow = value
    },
  },
  setup() {
    const {
      initTableConfig,
      createHeadItem,
      convertConfigPanel,
      addCloseButton,
      configRouteConvert,
    } = useView({})
    const config = _.cloneDeep(paymentConfigOrig)
    const personalTabs = _.cloneDeep(personalTabsOrig)

    const formAccountEdit = _.cloneDeep(formAccountEditOrig)
    const tableAccountBank = _.cloneDeep(tableAccountBankOrig)
    const formPaymentEdit = _.cloneDeep(formPaymentEditOrig)

    const { paymentConfig, zayavkaConfig } = initPaymentZayavka(
      paymentConfigOrig,
      zayavkaConfigOrig
    )

    const convertPathButtonAddSnak = () => {
      const tableSkan = personalTabs.find((el) => el.name === 'Сканы')
      const addBtn = tableSkan.config.panel.buttons.find(
        (el) => el.label === 'Добавить'
      )
      addBtn.type = 'pushUrl'
      addBtn.url = '/scan-add'
    }

    convertPathButtonAddSnak()
    configRouteConvert({
      config: paymentConfig.config,
      route: 'payment',
      newPath: 'personal-payment',
      settings: {
        index: [0],
      },
    })

    configRouteConvert({
      config: zayavkaConfig.config,
      route: 'zayavka',
      newPath: 'personal-zayavka',
      settings: {
        oldPath: 'id',
      },
    })
    personalTabs.splice(4, 0, ...[paymentConfig, zayavkaConfig])

    config.detail.tabs.push(...personalTabs)

    configRouteConvert({
      config: config.detail.tabs[4].config,
      route: 'scan',
      newPath: 'personal-scan',
      settings: {
        index: [0],
      },
    })

    configRouteConvert({
      config: config.detail.tabs[5].config,
      route: 'card_id',
      newPath: 'personal-card',
      settings: {
        index: [0],
      },
    })

    configRouteConvert({
      config: config,
      newPath: 'personal',
      settings: {
        oldPath: 'edit',
      },
    })

    config.detail.tabs.push(formAccountEdit, tableAccountBank)
    configRouteConvert({
      config: config,
      newPath: 'account',
      settings: {
        oldPath: 'edit',
      },
    })
    // config.detail.tabs.push(formPaymentEdit)
    // configRouteConvert({
    //   config: config,
    //   newPath: 'edit',
    //   settings: {
    //     oldPath: 'add-edit-logistic',
    //   },
    // })

    configRouteConvert({
      config: paymentConfig.config,
      route: 'payment',
      newPath: 'personal-payment',
      settings: {
        index: [0],
      },
    })

    configRouteConvert({
      config: zayavkaConfig.config,
      route: 'zayavka',
      newPath: 'personal-zayavka',
      settings: {
        oldPath: 'id',
      },
    })

    configRouteConvert({
      config: config,
      newPath: 'personal',
      // route: 'personal_id',
      settings: {
        oldPath: 'edit',
      },
    })
    const editTab = config.detail.tabs.find(
      (el) =>
        el.name === 'Добавить начисление' && el.path === 'add-edit-logistic'
    )
    const accountField = editTab.fields.find((el) => el.name === 'account_id')
    accountField.filter.push({
      with_me: false,
    })

    onMounted(() => {})
    return {
      config,
    }
  },
}
</script>
