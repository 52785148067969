import Vue, {
  watch,
  ref,
  computed,
  onMounted,
  toRef,
  getCurrentInstance,
} from 'vue'
import { useRoute } from 'vue-router/composables'
import { getList } from '@/api/selects'
import _ from 'lodash'
import store from '@/store'
import form from '@/store/modules/form'
import { useRouter } from 'vue-router/composables'

export default {
  name: 'copyField',
  props: {
    field: {
      type: Object,
      default: () => {},
    },
    value: {
      type: [String, Number, Object, Array],
    },
    formData: {
      type: Object,
    },
    originalData: {
      type: Object,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    fields: {
      type: Object,
    },
    entityData: {
      type: Object,
    },
    mode: {
      type: String,
    },
    environment: {
      type: Object,
    },
    getDepFilters: {
      type: Function,
    },
    items: {
      type: Array,
      default: null,
    },
    labelIcon: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    const router = useRouter()
    const context = ref({ context: ctx, props, router })

    const copyText = (text) => {
      const textarea = document.createElement('textarea')
      textarea.value = text
      textarea.style.position = 'absolute'
      textarea.style.left = '-99999999px'

      document.body.prepend(textarea)
      textarea.select()

      try {
        document.execCommand('copy')
      } catch (err) {
        console.log(err)
      } finally {
        textarea.remove()
        store.commit('notifies/showMessage', {
          color: 'success',
          content: 'Успешно скоппировано',
          timeout: 1000,
        })
      }
      // navigator.clipboard.writeText(text)
    }

    return { context, copyText }
  },
}
