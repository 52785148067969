import Vue, { computed, defineComponent, onMounted, ref, watch } from 'vue'
import FormError from '@/components/Task/el/FormError/index.vue'
import FormComment from '@/components/Task/el/FormComment/index.vue'
import store from '@/store'
import TextInfo from '@/components/Task/el/TextInfo/index.vue'
import useForm from '@/compositions/useForm'
import useRequest from '@/compositions/useRequest'
import { useRoute } from 'vue-router/composables'
import { useRouter } from 'vue-router/composables'
import form from '@/store/modules/form'
import { required } from '@/utils/validation'
import moment from 'moment/moment'
import Popup from '@/components/Popup/index.vue'
import Service from '@/components/Task/el/Service/default/index.vue'
import _ from 'lodash'
import DropZone from '@/components/Dropzone/default/index.vue'
import {
  dateField,
  stringField,
  selectField,
  autocompleteField,
  textareaField,
  datetimeField,
  dropZoneField,
  checkboxField,
  textBlock,
} from '@/utils/fields.js'

const Form41 = defineComponent({
  name: 'Form41',
  computed: {
    form() {
      return form
    },
  },
  components: {
    TextInfo,
    FormError,
    FormComment,
    Popup,
    Service,
    DropZone,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, ctx) {
    const { data } = props
    const route = useRoute()
    const router = useRouter()
    const { emit } = ctx
    const context = {
      root: {
        store,
        ctx,
        router,
      },
      data: props.data,
    }
    const loading = ref(false)
    const saveDisabled = computed(() => {
      if (
        requestData.value.some((x) => !x.formData.id) ||
        requestData.value.some((x) => x.formData.files.some((y) => !y.id))
      )
        return false
      return true
    })

    const textInfo = {
      date_target: {
        key: 'Срок',
        value: moment(
          JSON.parse(props.data.task.dop_data).date_target,
          'YYYY-MM-DD'
        ).format('DD.MM.YYYY'),
        isShow: (ctx) => {
          return JSON.parse(ctx.data.task?.dop_data)?.date_target
        },
      },
      comment: {
        key: 'Комментарий',
        value: JSON.parse(props.data.task.dop_data).comment,
        isShow: (ctx) => {
          return JSON.parse(ctx.data.task.dop_data).comment
        },
      },
    }

    const requestData = ref([])

    const addGroup = (obj) => {
      requestData.value.push(
        useForm({
          form: {
            fields: [
              stringField({
                label: '',
                name: 'fio',
                placeholder: '',
                class: [''],
                value: obj?.fio,
                position: {
                  cols: 12,
                  sm: 6,
                },
                validations: { required },
                bootstrapClass: [''],
              }),
              stringField({
                label: '',
                name: 'phone',
                placeholder: '',
                value: obj?.phone,
                class: [''],
                position: {
                  cols: 12,
                  sm: 6,
                },
                validations: { required },
                bootstrapClass: [''],
              }),
              textareaField({
                label: 'Комментарий',
                name: 'comment',
                placeholder: '',
                value: obj?.comment,
                class: [''],
                position: {
                  cols: 12,
                  sm: 12,
                },
                bootstrapClass: [''],
              }),
              dropZoneField({
                label: 'Документы',
                name: 'files',
                notPut: true,
                placeholder: '',
                value: obj?.files,
                class: [''],
                position: {
                  cols: 12,
                  sm: 12,
                },
                bootstrapClass: [''],
                options: {
                  removeble: true,
                  withoutSave: false,
                  folder: 'tmp',
                  name: '`task41`',
                  paramsForEmit: this,
                  countFiles: 10,
                },
              }),
              stringField({
                name: 'id',
                value: obj?.id,
                position: {
                  cols: 12,
                  sm: 0,
                },
              }),
              checkboxField({
                name: 'loading',
                value: false,
                position: {
                  cols: 12,
                  sm: 0,
                },
              }),
            ],
          },
          context,
          loading: loading.value,
        })
      )
    }

    const removeGroup = (index) => {
      if (requestData.value.length > 1) {
        if (index !== undefined) {
          requestData.value.splice(index, 1)
        } else {
          if (requestData.value.at(-1).formData.id) return
          requestData.value.pop()
        }
      }
    }

    const confirmTask = async () => {
      const validArray = requestData.value.reduce((acc, item) => {
        acc.push(item.validate(true))
        return acc
      }, [])
      if (validArray.some((item) => !item)) return
      loading.value = true

      const getRequestBodies = async (val) => {
        let body = {}
        if (val.files?.length) {
          const fileRequest = val.files.reduce(
            (fileAcc, file, fileIndex) => {
              if (file.id) return fileAcc
              const name =
                file.name.split('.')[0] +
                '_' +
                store?.state?.user.id +
                '_' +
                fileIndex +
                '_' +
                new Date().getTime()
              const ext = file.name.split('.').pop()
              const storeForm = new FormData()
              storeForm.append('name', name + '.' + ext)
              storeForm.append('file', file)
              const params = {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              }
              fileAcc.fileData.push(
                store.dispatch('file/create', {
                  data: storeForm,
                  folder: `tmp/${name}.${ext}`,
                  params,
                })
              )
              fileAcc.fileName.push(`/tmp/${name}.${ext}`)
              fileIndex += 1
              return fileAcc
            },
            {
              fileData: [],
              fileName: [],
            }
          )
          await Promise.all(fileRequest.fileData).then((res) => {
            body.files = fileRequest.fileName
          })
        }
        if (val.id) {
          if (!body.files?.length) return
          body = {
            contractor_id: val.id,
            ...body,
          }
        } else {
          body = {
            comment: val.comment ?? '',
            fio: val.fio,
            phone: val.phone,
            task_id: data.task.id,
            ...body,
          }
        }
        return body
      }

      await Promise.all(
        requestData.value.reduce((acc, item) => {
          acc.push(
            new Promise((resolve) => {
              resolve(getRequestBodies(item.formData))
            })
          )
          return acc
        }, [])
      )
        .then(async (response) => {
          response = response.filter((item) => item)
          await Promise.all(
            response.reduce((acc, item) => {
              acc.push(
                store.dispatch('form/create', {
                  url: item?.contractor_id
                    ? 'add/contractor_to_task_files'
                    : 'add/contractor_to_task',
                  body: {
                    data: item,
                  },
                })
              )
              return acc
            }, [])
          ).then((res) => {
            res.forEach((item, index) => {
              if (item.code === 1) {
                // requestData.value.splice(
                //   requestData.value.findIndex(
                //     (x) => x.name === request.nameArray[index]
                //   ),
                //   1
                // )
              } else {
                store.commit('notifies/showMessage', {
                  color: 'error',
                  content: 'Ошибка сервера',
                  timeout: 1000,
                })
              }
            })
            if (res.some((x) => x.code === 1)) getContractors()
          })
        })
        .finally(() => {
          loading.value = false
        })
    }

    const deleteContractor = async (item) => {
      item.loading = true
      const response = await store.dispatch('form/delForm', {
        url: `delete/contractor_from_task`,
        body: {
          data: {
            id: item.id,
            task_id: data.task.id,
          },
        },
      })
      item.loading = false
      if (response.code === 1) {
        await getContractors()
      } else {
        store.commit('notifies/showMessage', {
          color: 'error',
          content: 'Ошибка сервера',
          timeout: 1000,
        })
      }
    }

    const globalLoading = ref(false)
    const getContractors = async () => {
      requestData.value = []
      globalLoading.value = true
      const response = await store.dispatch('list/get', [
        {
          alias: 'contractors_task',
          filter: [
            {
              alias: 'task_id',
              value: [data.task.id],
            },
          ],
        },
      ])
      if (response.data.contractors_task.length) {
        response.data.contractors_task.forEach((item) => {
          addGroup(item)
        })
      } else {
        addGroup()
      }
      globalLoading.value = false
    }

    const imageFormat = (val) => {
      const allowedFormats = ['jpg', 'png', 'jpeg']
      const splitedName = val.split('.')
      const format = splitedName[splitedName.length - 1]
      if (allowedFormats.includes(format)) return true
      return false
    }

    const download = (url) => {
      Vue.downloadFile(url)
    }

    onMounted(async () => {
      await getContractors()
    })

    return {
      loading,
      globalLoading,
      confirmTask,
      requestData,
      removeGroup,
      addGroup,
      deleteContractor,
      imageFormat,
      download,
      saveDisabled,
      textInfo,
      context,
    }
  },
})
export default Form41
