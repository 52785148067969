import Vue, {
  ref,
  onMounted,
  computed,
  toRef,
  watch,
  provide,
  nextTick,
} from 'vue'
import { useRouter, useRoute } from 'vue-router/composables'
import useForm from '@/compositions/useForm.js'
import useRequest from '@/compositions/useRequest'

import store from '@/store'
import Autocomplete from '@/components/Autocomplete/form'
import Row from '../row/index.vue'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'Form-Rows',
  props: {
    tab: {
      type: Object,
      default: () => {},
    },
    tabs: {
      type: Array,
      default: () => [],
    },
    activeTab: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Autocomplete,
    Row,
  },
  setup(props, ctx) {
    const route = useRoute()
    const router = useRouter()
    const context = {
      root: {
        ctx,
        store,
        route,
        router,
      },
    }
    const { emit } = ctx
    const loading = ref(false)
    const targets = ref([])
    provide('targets', targets.value)
    const prevTab = ref({})
    const params = props?.tab?.lists
    const data = params
    const entries = ref([])
    const childFormData = ref([])

    const { makeRequest: makeRequestList } = useRequest({
      context,
      request: (data) => store.dispatch('list/get', data),
    })

    const drawItems = () => {
      const folowProperty = props.tab?.orderByFromLastTab
      const targetField = props.tabs[0].fields.find((x) => {
        {
          return x.name === folowProperty.name
        }
      })
      const targetIds = props.tab.formData[folowProperty.name]
      if (folowProperty.type === 'list' && folowProperty.alias) {
        Vue.set(
          entries,
          'value',
          targetField.items.reduce((acc, el) => {
            if (targetIds.includes(el.id)) {
              acc.push({
                id: el.id,
                [folowProperty.nameInTab]: el[folowProperty.alias],
              })
            }
            return acc
          }, [])
        )
      } else {
        Vue.set(entries, 'value', props.tabs[0].formData[folowProperty.name])
      }
      prevTab.value = props.tabs[0]
    }
    const rows = ref([])
    const changeForm = async ({ url, module }) => {
      rows.value.forEach((el, index) => {
        // validate = !el.validate()
        el.personalRef.forEach((pers) => {
          pers.validate(true)
        })
      })
      // let valid = false
      let valid = rows.value.every((el, index) => {
        // validate = !el.validate()
        return el.personalRef.every((pers) => {
          // return !pers.vForm.$invalid
          return pers.validate(true)
        })
      })
      if (!valid) return

      // rows.value.forEach((el) => el.validate(true))

      // const isValid = rows.value.every((el) => el.validate(true))

      // if (!isValid) return
      const {
        object_id,
        personal_id,
        doljnost_id,
        date_target,
        status,
        type_shift,
        with_nutrition,
        direction_id,
        sum_nutrition,
        vid_vedomost_id_logistic,
        manager_id,
      } = props.tab.formData
      const defaultData = {
        object_id,
        personal_id,
        account_id: store.state.user.id, // ?
        doljnost_id,
        //date_target: date_target[0],
        status,
        direction_id,
        comment: null,
        manager: manager_id, // ? миша отправит с объектом
        query_shift_personal: null,
        vid_vedomost_id: vid_vedomost_id_logistic,
        type_shift,
        with_nutrition,
        sum_nutrition,
      }
      let validate = null
      const accum = []
      const persons = []
      const persRefs = []

      const { makeRequest } = useRequest({
        context,
        request: () =>
          store.dispatch(module, {
            url,
            body: { persons },
          }),
        successMessage: `Успешно создано ${
          rows.value.length * props?.tab?.formData.personal_id.length
        } назначений`,
      })
      const result = await makeRequest()

      if (result?.data?.length) {
        store.commit('notifies/showMessage', {
          color: 'error',
          content: 'Некорректные назначения:',
          timeout: 1000,
        })
        result.data.forEach((el) => {
          const findedIndex = targets.value.findIndex(
            (target) => target.id === el.tid
          )

          const objectItems = props.tabs[0].fields.find(
            (field) => field.name === 'object_id'
          ).items
          const { name } = objectItems.find(
            (object) => object.id === props.tab.formData.object_id
          )
          const date = el.tid.split('_')[0]
          const dateFormated =
            date.split('-')[2] +
            '.' +
            date.split('-')[1] +
            '.' +
            date.split('-')[0]
          if (el.code === 1) {
            targets.value[
              findedIndex
            ].error = `На объект ${name} на дату ${dateFormated} выбранная учётная запись уже назначена`
          }
          if (el.code === 2) {
            targets.value[
              findedIndex
            ].error = `На объект ${name} на выбранную смену  ${dateFormated} числа выбранный сотрудник уже назначен`
          }
          emit('getItems')
        })
      } else {
        emit('getItems')
        emit('closePopup')
      }
    }
    const {
      formData,
      validate,
      formErrors,
      vForm,
      touchedForm,
      clickHandler,
      getData,
      changeAutocomplete,
      changeSelect,
      showField,
      getDepFilters,
    } = useForm({
      form: props.tab,
      // fields: fields(),
      context,
      loading,
      makeRequestList,
      changeForm,
      entries,
      childFormData,
      prevTab: prevTab,
    })
    onMounted(async () => {
      drawItems()
      watch(
        () => props.activeTab,
        () => {
          if (props.activeTab != 0) {
            Vue.set(entries, 'value', [])
            nextTick(drawItems)
          }
        }
      )
    })
    const activeTab = toRef(props, 'activeTab')
    return {
      clickHandler,
      loading,
      showField,
      formData,
      validate,
      formErrors,
      vForm,
      childFormData,
      touchedForm,
      changeAutocomplete,
      getData,
      changeSelect,
      prevTab,
      rows,
      targets,
      activeTab,
      getDepFilters,
      entries,
    }
  },
}
