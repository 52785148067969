import {
  dateField,
  stringField,
  selectField,
  autocompleteField,
  textareaField,
  datetimeField,
  checkboxField,
  dropZoneField,
  carouselField,
  docListField,
  colorPicker,
  textBlock,
  verticalAutocompleteField,
  customComponent,
} from '@/utils/fields.js'
import { stringAction } from '@/utils/actions'
import {
  required,
  minLength,
  maxLength,
  hasDate,
  hasTime,
} from '@/utils/validation.js'
import { v4 as uuidv4 } from 'uuid'
import { readonly } from 'vue'
import FormStage from '@/components/Form/stage/index.vue'
import TargetShift from '@/components/Form/targetShift/default/index.vue'
import FormDefault from '@/components/Form/default/index.vue'
import { brigade, query_shift } from '@/pages'
import customList from '@/components/List/default/index.vue'
import FormTarget from '@/components/Form/target/default/index.vue'

export const editFields = [
  selectField({
    label: 'Статус',
    name: 'status',
    alias: 'status_pt',
    placeholder: '',
    class: [''],
    selectOption: {
      text: 'name',
      value: 'id',
    },
    items: [],
    position: {
      cols: 12,
      sm: 6,
    },
    value: 1,
    disable: true,
    validations: { required },
    bootstrapClass: [''],
    hiding: {
      conditions: [
        {
          target: 'formData',
          field: 'status',
          value: [1],
          values: [1, 2],
        },
      ],
    },
  }),
  dateField({
    label: 'Дата статуса',
    name: 'date_create',
    value: '',
    type: 'datetime',
    subtype: 'datetime',
    readonly: true,
    menu: false,
    placeholder: '',
    class: [''],
    position: {
      cols: 12,
      sm: 6,
    },
    bootstrapClass: [''],
    disable: true,
    //mode: 'edit',
    isShow: true,
  }),
  //stringField({
  //  label: 'Создал',
  //  name: 'account_name',
  //  placeholder: '',
  //  readonly: false,
  //  class: [''],
  //  position: {
  //    cols: 12,
  //    sm: 6,
  //  },
  //  bootstrapClass: [''],
  //  mode: 'edit',
  //  //validations: { required },
  //  //isShow: false,
  //}),
  selectField({
    label: 'Направления',
    name: 'direction_id',
    alias: 'direction_id_logistic',
    placeholder: '',
    class: [''],
    selectOption: {
      text: 'name',
      value: 'id',
    },
    items: [],
    position: {
      cols: 12,
      sm: 6,
    },
    validations: { required },
    bootstrapClass: [''],
    //update: {
    //  module: 'selects/getList',
    //  fields: ['object_id'],
    //},
    readonly: {
      value: false,
      condition: [
        // {
        //   funcCondition: (context) =>
        //     context.store.state.user.permission_id === 7,
        //   type: true,
        // },
        {
          funcCondition: (context) =>
            context.store.state.user.id !== context.formData.account_id,
          type: true,
        },
      ],
    },
    dependence: [
      {
        type: 'api',
        module: 'selects/getListUpdate',
        field: 'object_id',
        url: 'get/pagination_list/target_object',
      },
    ],
    updateList: [
      {
        alias: 'doljnost_id_logistic',
        filter: [
          {
            field: 'direction_id',
            value: '',
            source: 'formData',
            type: 'array',
          },
        ],
      },
    ],
  }),
  selectField({
    label: 'Вид ведомости',
    name: 'vid_vedomost_id',
    alias: 'vid_vedomost_id_logistic',
    placeholder: '',
    class: [''],
    selectOption: {
      text: 'name',
      value: 'id',
    },
    items: [],
    position: {
      cols: 12,
      sm: 6,
    },
    validations: { required },
    bootstrapClass: [''],
    // Прятать option от условия, target - цель условия, value - значение, value - значения которые нужно прятать
    hiding: {
      conditions: [
        {
          target: 'mode',
          value: 'edit',
          values: [8],
        },
        {
          target: 'mode',
          value: 'add',
          values: [2, 3, 4, 5, 6, 7],
        },
      ],
    },
    readonly: {
      value: false,
      condition: [
        {
          funcCondition: (context) =>
            context.store.state.user.id !== context.formData.account_id,
          type: true,
        },
      ],
    },
    requiredFields: ['personal_id'],
  }),
  autocompleteField({
    label: 'Объект',
    name: 'object_id',
    subtype: 'single',
    placeholder: '',
    class: [''],
    selectOption: {
      text: 'name',
      value: 'id',
    },
    items: [],
    page: 1,
    search: '',
    url: 'get/pagination_list/target_object',
    position: {
      cols: 12,
      sm: 6,
    },
    validations: { required },
    bootstrapClass: [''],
    filter: [
      {
        field: 'direction_id',
        value: '',
        source: 'formData',
      },
    ],
    dependence: [
      {
        type: 'default',
        fillField: ['sum_nutrition', 'with_nutrition', 'type'],
      },
      {
        type: 'api',
        module: 'selects/getListUpdate',
        field: 'personal_id',
        url: 'get/pagination_list/target_personal',
        filter: [
          {
            field: 'object_id',
            value: '',
            source: 'formData',
          },
        ],
      },
    ],
    update: {
      module: 'selects/getList',
      fields: ['personal_id'],
    },
    requiredFields: ['direction_id'],
    readonly: {
      value: false,
      condition: [
        {
          funcCondition: (context) =>
            context.store.state.user.id !== context.formData.account_id,
          type: true,
        },
      ],
    },
  }),
  autocompleteField({
    label: 'Линейщик',
    name: 'personal_id',
    subtype: 'single',
    placeholder: '',
    class: [''],
    selectOption: {
      text: 'name',
      value: 'id',
    },
    items: [],
    page: 1,
    search: '',
    url: 'get/pagination_list/target_personal',
    position: {
      cols: 12,
      sm: 6,
    },
    validations: { required },
    bootstrapClass: [''],
    filter: [
      {
        field: 'object_id',
        value: '',
        source: 'formData',
      },
    ],
    requiredFields: ['object_id'],
    readonly: {
      value: false,
      condition: [
        {
          funcCondition: (context) =>
            context.store.state.user.id !== context.formData.account_id,
          type: true,
        },
      ],
    },
  }),
  selectField({
    label: 'Должность',
    name: 'doljnost_id',
    alias: 'doljnost_id_logistic',
    placeholder: '',
    class: [''],
    selectOption: {
      text: 'name',
      value: 'id',
    },
    items: [],
    position: {
      cols: 12,
      sm: 6,
    },
    validations: { required },
    bootstrapClass: [''],
    readonly: {
      value: false,
      condition: [
        {
          funcCondition: (context) =>
            context.store.state.user.id !== context.formData.account_id,
          type: true,
        },
      ],
    },
  }),
  selectField({
    label: 'Тип смены',
    name: 'type_shift',
    alias: 'shifts',
    placeholder: '',
    class: [''],
    selectOption: {
      text: 'name',
      value: 'id',
    },
    items: [],
    position: {
      cols: 12,
      sm: 6,
    },
    validations: { required },
    bootstrapClass: [''],
    readonly: {
      value: false,
      condition: [
        {
          funcCondition: (context) =>
            context.store.state.user.id !== context.formData.account_id,
          type: true,
        },
      ],
    },
  }),
  dateField({
    label: 'На дату',
    name: 'date_target',
    value: '',
    type: 'date',
    //subtype: 'single',
    menu: false,
    placeholder: '',
    class: [''],
    position: {
      cols: 12,
      sm: 12,
    },
    validations: { required },
    bootstrapClass: [''],
    disable: false,
    //mode: 'edit',
    isShow: true,
    readonly: {
      value: false,
      condition: [
        {
          funcCondition: (context) =>
            context.store.state.user.id !== context.formData.account_id,
          type: true,
        },
      ],
    },
  }),
  checkboxField({
    label: 'Питание',
    name: 'with_nutrition',
    placeholder: '',
    value: false,
    class: [''],
    position: {
      cols: 12,
      sm: 6,
    },
    bootstrapClass: [''],
    //validations: { required },
    //isShow: false,
    readonly: {
      value: false,
      condition: [
        {
          funcCondition: (context) =>
            context.store.state.user.id !== context.formData.account_id,
          type: true,
        },
      ],
    },
  }),
  stringField({
    label: 'Стоимость питания',
    name: 'sum_nutrition',
    placeholder: '',
    class: [''],
    position: {
      cols: 12,
      sm: 6,
    },
    readonly: true,
    validations: { required },
    bootstrapClass: [''],
    requiredFields: ['with_nutrition', 'sum_nutrition'],
    isShow: {
      value: false,
      conditions: [
        {
          field: 'with_nutrition',
          value: [1, true],
        },
      ],
    },
  }),
  //selectField({
  //  label: 'Менеджер',
  //  name: 'account_id',
  //  subtype: 'single',
  //  placeholder: '',
  //  class: [''],
  //  selectOption: {
  //    text: 'name',
  //    value: 'id',
  //  },
  //  items: [],
  //  position: {
  //    cols: 12,
  //    sm: 6,
  //  },
  //  readonly: {
  //    value: false,
  //    condition: [
  //      {
  //        target: 'formData',
  //        field: 'status',
  //        value: [4],
  //      },
  //      {
  //        permissions: [3, 15],
  //        field: 'status',
  //        value: [3],
  //        type: false,
  //      },
  //    ],
  //  },
  //  validations: { required },
  //  bootstrapClass: [''],
  //}),
  autocompleteField({
    label: 'Учетная запись',
    name: 'user_key',
    alias: 'avatar_with_user_key_id',
    requestKey: 'avatar_with_user_key_id',
    subtype: 'single',
    placeholder: '',
    class: [''],
    selectOption: {
      text: 'name',
      value: 'id',
    },
    items: [],
    page: 1,
    search: '',
    url: 'get/pagination_list/avatar_with_user_key_id',
    position: {
      cols: 12,
      sm: 12,
    },
    validations: { required },
    bootstrapClass: [''],
    filter: [
      {
        field: 'object_id',
        value: '',
        source: 'fromPrev',
      },
    ],
    updateList: [
      {
        alias: 'print_form_key',
        filter: [
          {
            field: 'object_id',
            value: '',
            source: 'formData',
            type: 'num',
          },
          {
            field: 'personal_id',
            value: '',
            source: 'formData',
            type: 'num',
          },
        ],
      },
    ],
    readonly: {
      value: false,
      condition: [
        {
          funcCondition: (context) =>
            context.store.state.user.id !== context.formData.account_id,
          type: true,
        },
      ],
    },
  }),
  textBlock({
    label: 'Создал',
    name: 'type',
    placeholder: '',
    readonly: true,
    class: [''],
    position: {
      cols: 12,
      sm: 12,
    },
    bootstrapClass: [''],
    //validations: { required },
    //isShow: false,
  }),
  selectField({
    label: 'Ключ',
    name: 'print_form_key',
    //withoutList: true,
    //alias: 'direction_id_logistic',
    placeholder: '',
    class: [''],
    selectOption: {
      text: 'name',
      value: 'id',
    },
    items: [],
    position: {
      cols: 12,
      sm: 12,
    },
    validations: { required },
    bootstrapClass: [''],
    customList: {
      type: 'api',
      module: 'personal/getKeys',
      //url: 'object_id/avatar_with_user_key_id',
      field: 'print_form_key',
      url: [
        {
          source: 'formData',
          field: 'object_id',
        },
        {
          source: 'formData',
          field: 'this',
        },
      ],
    },
    isShow: {
      value: false,
      conditions: [
        {
          field: 'type',
          value: [11, 12],
        },
        {
          field: 'direction_id',
          value: [1],
        },
        {
          field: 'doljnost_id',
          value: [5, 7, 8, 23, 33],
        },
      ],
    },
  }),
  //textBlock({
  //  label: 'Создал',
  //  name: 'account_id',
  //  placeholder: '',
  //  readonly: true,
  //  class: [''],
  //  position: {
  //    cols: 12,
  //    sm: 12,
  //  },
  //  bootstrapClass: [''],
  //  //validations: { required },
  //  //isShow: false,
  //}),
  textBlock({
    label: 'Создал',
    name: 'is_close',
    placeholder: '',
    readonly: true,
    class: [''],
    position: {
      cols: 12,
      sm: 12,
    },
    bootstrapClass: [''],
    //validations: { required },
    //isShow: false,
  }),
  textBlock({
    label: 'Создал',
    name: 'account_id',
    placeholder: '',
    readonly: true,
    notSend: true,
    class: [''],
    position: {
      cols: 12,
      sm: 12,
    },
    bootstrapClass: [''],
    //validations: { required },
    //isShow: false,
  }),
  textBlock({
    label: 'Создал',
    name: 'readonly',
    placeholder: '',
    readonly: true,
    class: [''],
    position: {
      cols: 12,
      sm: 12,
    },
    bootstrapClass: [''],
    //validations: { required },
    //isShow: false,
  }),
]

export default {
  id: 0,
  name: 'Основные',
  type: FormStage,
  detail: true,
  path: 'add',
  stages: [
    {
      id: 0,
      name: 'Основные',
      type: FormDefault,
      detail: {
        type: 'popup', // String 'popup' or 'page'
        classes: [''], // List class
        width: '1000px',
        method: 'get',
        alias: 'object',
        url: '/get/form/',
        requestId: 'target_id',
        name: 'Назначение',
        bootstrapClass: [''], // List class from bootstrap ( col-6, pa-2... )
        tabs: [
          {
            id: 1,
            name: 'Основные',
            type: FormTarget,
            detail: true,
            path: 'target-edit',
            requestId: 'target_id',
            routeParam: 'target_id',
            initDepStart: true,
            lists: [
              { alias: 'vid_vedomost_id_logistic', filter: [] },
              { alias: 'status_pt', filter: [] },
              // { alias: 'object_id_logistic', filter: [] },
              // { alias: 'account_id_logistic', filter: [] },
              { alias: 'direction_id_logistic', filter: [] },
              {
                alias: 'doljnost_id_logistic',
                filter: [
                  {
                    field: 'direction_id',
                    value: '',
                    source: 'formData',
                    type: 'array',
                  },
                ],
              },
              { alias: 'shifts', filter: [] },
              { alias: 'nutritions', filter: [] },
              //{
              //  alias: 'account_id',
              //  filter: [],
              //},
              {
                alias: 'print_form_key',
                filter: [
                  {
                    field: 'object_id',
                    value: '',
                    source: 'formData',
                    type: 'num',
                  },
                  {
                    field: 'personal_id',
                    value: '',
                    source: 'formData',
                    type: 'num',
                  },
                ],
              },
            ],
            alias: 'personal_target',
            active: false,
            fields: editFields,
            actions: [
              stringAction({
                text: 'Закрыть',
                type: 'submit',
                color: 'textDefault',
                name: 'closePopup',
                buttonType: 'text',
                action: 'func',
                func: () => {
                  return true
                },
                skipValidation: true,
              }),
              stringAction({
                text: 'Удалить',
                type: 'submit',
                module: 'form/del',
                color: 'error',
                url: 'delete/personal_target',
                name: 'deleteFormById',
                action: 'deleteFormById',
                isHide: {
                  value: false,
                  type: 'every',
                  condition: [
                    {
                      field: 'is_close',
                      target: 'formData',
                      value: [1],
                      type: true,
                    },
                    {
                      permissions: [3, 15, 4],
                      field: 'status',
                      target: 'formData',
                      value: [2],
                      type: false,
                    },
                    {
                      permissions: [1, 3, 15, 4, 13],
                      field: 'status',
                      target: 'formData',
                      value: [1],
                      type: false,
                    },
                    {
                      permissions: [3, 15, 4],
                      field: 'status',
                      target: 'formData',
                      value: [3],
                      type: false,
                    },
                  ],
                },
              }),
              stringAction({
                text: 'Сохранить',
                type: 'submit',
                module: 'personal_target/update',
                name: 'saveForm',
                url: 'update/target',
                action: 'saveForm',
                color: 'primary',
                successMessage: false,
                isHide: {
                  value: false,
                  type: 'every',
                  condition: [
                    {
                      field: 'readonlyAll',
                      target: 'environment',
                      value: [1],
                      type: true,
                    },
                  ],
                },
              }),
            ],
            formData: {},
          },
        ],
        activeTab: null,
      },
      lists: [
        { alias: 'query_shift_status', filter: [] },
        { alias: 'query_shift_doljnost', filter: [] },
        // {
        //   alias: 'query_shift_status',
        //   filter: [
        //     {
        //       field: 'direction_id',
        //       value: '',
        //       source: 'formData',
        //       type: 'array',
        //     },
        //   ],
        // },
        { alias: 'query_shift_type_shift', filter: [] },
      ],
      alias: 'query_shift',
      active: false,
      fields: [
        selectField({
          label: 'Статус',
          name: 'status_id',
          alias: 'query_shift_status',
          notSend: true,
          placeholder: '',
          class: [''],
          selectOption: {
            text: 'name',
            value: 'id',
          },
          items: [],
          position: {
            cols: 12,
            sm: 6,
          },
          value: 1,
          readonly: true,
          disable: true,
          validations: { required },
          bootstrapClass: [''],
        }),
        autocompleteField({
          label: 'Объект',
          name: 'object_id',
          subtype: 'single',
          placeholder: '',
          class: [''],
          selectOption: {
            text: 'name',
            value: 'id',
          },
          items: [],
          page: 1,
          search: '',
          url: 'get/pagination_list/query_shift_object',
          position: {
            cols: 12,
            sm: 6,
          },
          validations: { required },
          bootstrapClass: [''],
          filter: [
            // {
            //   field: 'direction_id',
            //   required: true,
            //   value: '',
            // },
          ],
          dependence: [
            {
              type: 'api',
              module: 'selects/getListUpdate',
              field: 'account_id',
              url: 'get/pagination_list/query_shift_account',
              filter: [
                {
                  field: 'date_target',
                  value: '',
                },
                {
                  field: 'object_id',
                  value: '',
                },
              ],
            },
          ],
        }),
        dateField({
          label: 'На дату',
          name: 'date_target',
          value: [],
          type: 'date',
          subtype: 'single',
          readonly: false,
          menu: false,
          placeholder: '',
          class: [''],
          position: {
            cols: 12,
            sm: 6,
          },
          validations: { required },
          bootstrapClass: [''],
          disable: false,
          //mode: 'edit',
          isShow: true,
          dependence: [
            {
              type: 'api',
              module: 'selects/getListUpdate',
              field: 'account_id',
              url: 'get/pagination_list/query_shift_account',
              filter: [
                {
                  field: 'date_target',
                  value: '',
                },
                {
                  field: 'object_id',
                  value: '',
                },
              ],
            },
          ],
        }),
        autocompleteField({
          label: 'Менеджер',
          name: 'account_id',
          subtype: 'single',
          placeholder: '',
          class: [''],
          selectOption: {
            text: 'name',
            value: 'id',
          },
          items: [],
          page: 1,
          search: '',
          url: 'get/pagination_list/query_shift_account',
          position: {
            cols: 12,
            sm: 6,
          },
          validations: { required },
          bootstrapClass: [''],
          filter: [],
          requiredFields: ['direction_id'],
        }),
        selectField({
          label: 'Должность',
          name: 'doljnost_id',
          alias: 'query_shift_doljnost',
          placeholder: '',
          class: [''],
          selectOption: {
            text: 'name',
            value: 'id',
          },
          items: [],
          position: {
            cols: 12,
            sm: 6,
          },
          validations: { required },
          bootstrapClass: [''],
        }),
        selectField({
          label: 'Тип смены',
          name: 'type_shift_id',
          alias: 'query_shift_type_shift',
          placeholder: '',
          class: [''],
          selectOption: {
            text: 'name',
            value: 'id',
          },
          items: [],
          position: {
            cols: 12,
            sm: 6,
          },
          validations: { required },
          bootstrapClass: [''],
        }),
        autocompleteField({
          label: 'Бригада',
          name: 'brigade_id',
          subtype: 'single',
          placeholder: '',
          class: [''],
          selectOption: {
            text: 'name',
            value: 'id',
          },
          items: [],
          page: 1,
          value: 0,
          search: '',
          url: 'get/pagination_list/query_shift_brigade',
          position: {
            cols: 12,
            sm: 12,
          },
          bootstrapClass: [''],
          filter: [],
          isShow: {
            value: true,
            conditions: [
              {
                target: 'funcCondition',
                funcCondition: (ctx) => {
                  return ctx.environment.mode === 'edit'
                },
              },
            ],
          },
          dependence: [
            {
              type: 'api',
              module: 'selects/getListUpdate',
              field: 'personals',
              url: 'get/pagination_list/query_shift_personal',
            },
            {
              type: 'default',
              fillField: ['personals'],
            },
            // {
            //   type: 'api',
            //   module: 'selects/getListUpdate',
            //   field: 'personals_without_brigade',
            //   url: 'get/pagination_list/query_shift_personal',
            // },

            // {
            //   init: true,
            //   type: 'custom',
            //   func: (ctx) => {
            //     if (!ctx.formData.brigade_id) {
            //       ctx.formData.brigade_id = 0
            //       ctx.formData.personals = null
            //     } else ctx.formData.personals_without_brigade = null
            //   },
            // },
          ],
          readonly: {
            value: true,
            condition: [
              {
                funcCondition: (ctx) => {
                  return ctx.entityData.status_id === 1
                },
                type: false,
              },
            ],
          },
        }),
        customComponent({
          component: customList,
          useProperty: 'personals',
          position: {
            cols: 12,
            sm: 12,
          },
          head: [
            {
              alias: 'personal_name',
              type: 'text',
            },
            {
              alias: 'personal_target_id',
              type: 'button',
              color: 'info',
              preffix: 'Назначение №',
              method: 'openFormById',
              action: 'personal_target',
              func: (ctx, row) => {
                ctx.context.emit('openPopup')
                ctx.router.push({
                  path:
                    ctx.router.currentRoute.path + '/' + row.personal_target_id,
                })
              },
              class: [],
            },
          ],
          class: [''],
          isShow: {
            value: true,
            conditions: [
              {
                target: 'funcCondition',
                funcCondition: (ctx) => {
                  return ctx.entityData.status_id === 2
                },
              },
            ],
          },
        }),
        verticalAutocompleteField({
          label: 'Сотрудники',
          name: 'personals',
          subtype: 'multiple',
          placeholder: '',
          class: [''],
          selectOption: {
            text: 'name',
            value: 'id',
          },
          setAllItemsOnUpdate: true,
          dontSendId: true,
          items: [],
          page: 1,
          search: '',
          url: 'get/pagination_list/query_shift_personal',
          position: {
            cols: 12,
            sm: 12,
          },
          validations: { required },
          bootstrapClass: [''],
          isShow: {
            value: true,
            conditions: [
              {
                target: 'funcCondition',
                funcCondition: (ctx) => {
                  return (
                    ctx.environment.mode === 'edit' &&
                    ctx.entityData.status_id === 1
                  )
                },
              },
            ],
          },
          filter: [
            {
              field: 'brigade_id',
              value: '',
              source: 'formData',
            },
          ],
          // requiredFields: ['brigade_id'],
        }),
        // verticalAutocompleteField({
        //   label: 'Сотрудники',
        //   name: 'personals_without_brigade',
        //   subtype: 'multiple',
        //   placeholder: '',
        //   class: [''],
        //   selectOption: {
        //     text: 'name',
        //     value: 'id',
        //   },
        //   setAllItemsOnUpdate: true,
        //   items: [],
        //   page: 1,
        //   search: '',
        //   url: 'get/pagination_list/query_shift_personal',
        //   position: {
        //     cols: 12,
        //     sm: 12,
        //   },
        //   validations: { required },
        //   bootstrapClass: [''],
        //   isShow: {
        //     value: true,
        //     conditions: [
        //       {
        //         target: 'funcCondition',
        //         funcCondition: (ctx) => {
        //           return (
        //             ctx.environment.mode === 'edit' &&
        //             !ctx.formData.brigade_id &&
        //             ctx.entityData.status_id === 1
        //           )
        //         },
        //       },
        //     ],
        //   },
        //   dependence: [
        //     {
        //       init: true,
        //       type: 'custom',
        //       func: (ctx) => {
        //         if (ctx.formData?.personals_without_brigade?.length)
        //           ctx.formData.personals =
        //             ctx.formData.personals_without_brigade
        //       },
        //     },
        //   ],
        //   filter: [
        //     // {
        //     //   field: 'brigade_id',
        //     //   value: '',
        //     //   source: '0',
        //     // },
        //   ],
        // }),
        stringField({
          label: 'Примечание',
          name: 'note',
          placeholder: '',
          readonly: false,
          class: [''],
          position: {
            cols: 12,
            sm: 12,
          },
          bootstrapClass: [''],
        }),
      ],
      actions: [
        stringAction({
          text: 'Закрыть',
          type: 'submit',
          color: 'textDefault',
          name: 'closePopup',
          action: 'closePopup',
          to: 'query_shift',
          skipValidation: true,
        }),
        stringAction({
          text: 'Сохранить',
          type: 'submit',
          color: 'primary',
          module: 'form/create',
          url: 'create/query_shift',
          name: 'createForm',
          action: 'createForm',
          handlingResponse: {
            1: {
              text: 'Успешно',
              color: 'success',
            },
            2: {
              text: 'Ошибка на стороне сервера',
              color: 'error',
            },
            3: {
              text: 'Не хватает данных',
              color: 'warning',
            },
            4: {
              text: 'Нет доступа',
              color: 'error',
            },
            5: {
              text: 'Данные не проходят валидацию',
              color: 'error',
            },
            6: {
              text: 'Заявка с таким названием уже существует',
              color: 'warning',
            },
          },
          isHide: {
            value: false,
            type: 'every',
            condition: [
              {
                field: 'mode',
                target: 'environment',
                value: ['edit'],
                type: true,
              },
              {
                funcCondition: (ctx) => {
                  return ctx.entityData.status_id === 2
                },
                type: true,
              },
            ],
          },
        }),
        stringAction({
          text: 'Сохранить',
          type: 'submit',
          module: '',
          name: 'saveForm',
          action: 'nextStage',
          color: 'primary',
          isHide: {
            value: false,
            type: 'every',
            condition: [
              {
                field: 'mode',
                target: 'environment',
                value: ['add'],
                type: true,
              },
              {
                funcCondition: (ctx) => {
                  return ctx.entityData.status_id === 2
                },
                type: true,
              },
            ],
          },
        }),
      ],
      formData: {},
    },
    {
      id: 1,
      name: 'Основные',
      type: TargetShift,
      lists: [
        { alias: 'query_shift_type_tariff', filter: [] },
        {
          alias: 'query_shift_vid_vedomost_id',
          filter: [
            {
              field: 'date_target',
              value: '',
              source: 'fromPrev',
            },
            {
              field: 'personal_id',
              value: '',
              source: 'formData',
            },
          ],
        },
      ],
      alias: 'query_shift',
      active: false,
      orderByFromLastTab: {
        name: 'personals',
        alias: 'name',
        nameInTab: 'account_name',
        type: 'list',
        pasteIdInField: 'personal_id',
      },
      fields: [
        textBlock({
          label: 'Создал',
          name: 'account_name',
          placeholder: '',
          readonly: true,
          class: [''],
          position: {
            cols: 12,
            sm: 3,
          },
          value: '',
          bootstrapClass: [''],
          //validations: { required },
          //isShow: false,
        }),
        autocompleteField({
          label: 'Учетная запись',
          name: 'personal_id',
          subtype: 'single',
          placeholder: '',
          class: ['flexFlow'],
          selectOption: {
            text: 'name',
            value: 'id',
          },
          items: [],
          readonly: true,
          page: 1,
          search: '',
          url: 'get/pagination_list/query_shift_personal',
          position: {
            cols: 12,
            sm: 6,
          },
          validations: { required },
          //: true,
          // validations: { required },
          bootstrapClass: [''],
          filter: [
            {
              field: 'brigade_id',
              value: '',
              source: 'fromPrev',
            },
          ],
        }),
        selectField({
          label: 'Вид ведомости',
          name: 'vid_vedomost_id',
          alias: 'query_shift_vid_vedomost_id',
          placeholder: '',
          class: [''],
          selectOption: {
            text: 'name',
            value: 'id',
          },
          items: [],
          position: {
            cols: 12,
            sm: 3,
          },
          validations: { required },
          filter: [
            {
              field: 'date_target',
              value: '',
              source: 'fromPrev',
            },
            {
              field: 'personal_id',
              value: '',
              source: 'formData',
            },
          ],
          // validations: { required },
          bootstrapClass: [''],
          // Прятать option от условия, target - цель условия, value - значение, value - значения которые нужно прятать
          hiding: {
            conditions: [
              {
                target: 'mode',
                value: 'add',
                values: [2, 3, 4, 5, 6, 7],
              },
            ],
          },
        }),
      ],
      actions: [
        stringAction({
          text: 'Назад',
          type: 'submit',
          module: '',
          name: 'prevStage',
          action: 'prevStage',
          color: 'normal',
          skipValidation: true,
        }),
        stringAction({
          text: 'Создать',
          type: 'submit',
          module: 'form/create',
          url: 'create/multiple_target',
          name: 'custom',
          action: 'func',
          color: 'primary',
          func: async (ctx) => {
            const personals = ctx.childFormData.value
            personals.map((item) => delete item.account_name)
            const requestData = {
              personals,
              brigade_id: ctx.prevTab.value.formData.brigade_id,
              query_shift_id: parseInt(ctx.context.root.route.params.id),
            }
            const data = await ctx.context.root.store.dispatch('form/create', {
              url: 'add/query_shift/personal',
              body: {
                data: requestData,
              },
            })
            if (data.code === 1) {
              ctx.store.commit('notifies/showMessage', {
                color: 'success',
                content: 'Успешно',
                timeout: 1000,
              })
              return true
            }
            if (data.code === 2) {
              ctx.store.commit('notifies/showMessage', {
                color: 'error',
                content: 'Ошибка сервера',
                timeout: 2000,
              })
            } else if (data.code === 3) {
              ctx.store.commit('notifies/showMessage', {
                color: 'warning',
                content: 'Попробуйте позже',
                timeout: 2000,
              })
            } else if (data.code === 4) {
              ctx.store.commit('notifies/showMessage', {
                color: 'warning',
                content: 'Нет доступа',
                timeout: 2000,
              })
            } else if (data.code === 5) {
              ctx.store.commit('notifies/showMessage', {
                color: 'warning',
                content: 'Не существующий id заявки',
                timeout: 2000,
              })
            } else if (data.code === 6) {
              ctx.store.commit('notifies/showMessage', {
                color: 'warning',
                content: 'На объект не найден прораб',
                timeout: 3000,
              })
            } else if (data.code === 7) {
              ctx.store.commit('notifies/showMessage', {
                color: 'warning',
                content: 'На объект не найден тариф',
                timeout: 3000,
              })
            }
          },
          skipValidation: true,
        }),
      ],
      formData: {},
    },
  ],
}
