var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-row',_vm._l((_vm.tab.fields),function(field){return _c('v-col',{key:field.id,ref:"fieldsRef",refInFor:true,staticClass:"field-col",class:[
        field.type,
        _vm.readonlyField(field) ? 'readonly' : '',
        typeof field.isShow === 'object' && !field.isShow.value
          ? 'isHide'
          : '',
      ],attrs:{"cols":field.position.cols,"sm":_vm.colsField(field),"name":field.name}},[(_vm.isRequired(field))?_c('div',{staticClass:"isRequired d-flex justify-end color-red",class:_vm.formErrors[field?.name]?.length ? 'error--text' : ''},[_vm._v(" * ")]):_vm._e(),(
          _vm.loading &&
          field.isShow &&
          ((typeof field.isShow === 'boolean' && field.isShow) ||
            (typeof field.isShow === 'object' && field.isShow.value))
        )?_c('div',{staticClass:"field-loading gradient"}):(_vm.showField('select', field))?_c('Autocomplete',{ref:"autocompleteRef",refInFor:true,class:[...field.class],attrs:{"field":field,"error-messages":_vm.formErrors[field?.name],"formData":_vm.formData,"readonly":_vm.readonlyField(field),"fields":_vm.fields,"mode":_vm.mode,"environment":_vm.environment,"labelIcon":field.labelIcon && _vm.customFunc(field.labelIcon.isShow)},on:{"changeValue":(item) =>
            _vm.changeAutocomplete({
              value: _vm.formData[field.name],
              field,
              item,
            })},model:{value:(_vm.formData[field.name]),callback:function ($$v) {_vm.$set(_vm.formData, field.name, $$v)},expression:"formData[field.name]"}}):(_vm.showField('autocomplete', field))?_c('Autocomplete',{ref:"autocompleteRef",refInFor:true,class:[...field.class],attrs:{"field":field,"error-messages":_vm.formErrors[field?.name],"formData":_vm.formData,"readonly":_vm.readonlyField(field),"fields":_vm.fields,"mode":_vm.mode,"environment":_vm.environment,"getDepFilters":_vm.getDepFilters,"labelIcon":field.labelIcon && _vm.customFunc(field.labelIcon.isShow)},on:{"changeValue":(item) =>
            _vm.changeAutocomplete({
              value: _vm.formData[field.name],
              field,
              item,
            })},model:{value:(_vm.formData[field.name]),callback:function ($$v) {_vm.$set(_vm.formData, field.name, $$v)},expression:"formData[field.name]"}}):(_vm.showField('string', field))?_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(field.mask),expression:"field.mask"}],class:[...field.class],attrs:{"label":field.label,"placeholder":field?.placeholder,"error-messages":_vm.formErrors[field?.name],"clearable":"","readonly":_vm.readonlyField(field),"disabled":_vm.disabledField(field),"name":field.name},on:{"input":function($event){return _vm.changeValue({
            value: _vm.formData[field.name],
            field,
          })}},scopedSlots:_vm._u([{key:"label",fn:function(){return [(field.labelIcon && _vm.customFunc(field.labelIcon.isShow))?_c('v-icon',{staticClass:"pb-1 mr-2",attrs:{"size":"20","color":field.labelIcon.color}},[_vm._v(_vm._s(field.labelIcon.icon))]):_vm._e(),_c('span',[_vm._v(_vm._s(field.label))])]},proxy:true},(
            field?.appendAction?.length || field?.hasOwnProperty('appendInfo')
          )?{key:"append-outer",fn:function(){return [_vm._l((field.appendAction),function(action){return (field?.appendAction?.length)?_c('v-tooltip',{key:action.label,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(_vm.appendActionShow(action))?_c('v-btn',_vm._g(_vm._b({class:action.class,attrs:{"small":""},on:{"click":function($event){return _vm.appendFieldHandler({ action, field })}}},'v-btn',attrs,false),on),[_c('v-tooltip',{attrs:{"activator":"parent","location":"top"}},[_vm._v("Tooltip")]),_c('v-icon',[_vm._v(_vm._s(action.icon))])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(action.label))])]):_vm._e()}),(
              field?.hasOwnProperty('appendInfo') &&
              _vm.getAppendInfo(field.appendInfo.method)
            )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2 mr-3 pb-3",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":'orange'}},[_vm._v("mdi-information")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getAppendInfo(field.appendInfo.method)))])]):_vm._e()]},proxy:true}:null],null,true),model:{value:(_vm.formData[field.name]),callback:function ($$v) {_vm.$set(_vm.formData, field.name, $$v)},expression:"formData[field.name]"}}):(_vm.showField('textBlock', field))?_c('div',{staticClass:"key_name"},[_vm._v(" "+_vm._s(_vm.formData[field.name])+" ")]):(_vm.showField('checkbox', field))?_c('v-checkbox',{class:'checkbox_' + field.name,attrs:{"label":field.label,"disabled":_vm.disabledField(field),"readonly":_vm.readonlyField(field),"name":field.name},on:{"change":function($event){return _vm.changeValue({
            value: _vm.formData[field.name],
            field,
          })}},model:{value:(_vm.formData[field.name]),callback:function ($$v) {_vm.$set(_vm.formData, field.name, $$v)},expression:"formData[field.name]"}}):(_vm.showField('date', field))?_c('Datepicker',{attrs:{"field":field,"label":field.label,"error-messages":_vm.formErrors[field?.name],"disabled":_vm.disabledField(field),"readonly":_vm.readonlyField(field)},on:{"change":function($event){return _vm.changeAutocomplete({ field, value: _vm.formData[field.name] })},"changeMonth":_vm.changeMonth},model:{value:(_vm.formData[field.name]),callback:function ($$v) {_vm.$set(_vm.formData, field.name, $$v)},expression:"formData[field.name]"}}):(_vm.showField('textarea', field))?_c('v-textarea',{attrs:{"label":field.label,"error-messages":_vm.formErrors[field?.name],"clearable":"","rows":"1","disabled":_vm.disabledField(field),"readonly":_vm.readonlyField(field)},on:{"change":function($event){return _vm.changeAutocomplete({ field, value: _vm.formData[field.name] })}},model:{value:(_vm.formData[field.name]),callback:function ($$v) {_vm.$set(_vm.formData, field.name, $$v)},expression:"formData[field.name]"}}):(_vm.showField('datetime', field))?_c('Datetimepicker',{attrs:{"label":field.label,"clearable":"","error-messages":_vm.formErrors[field?.name],"readonly":_vm.readonlyField(field),"field":field},on:{"change":function($event){return _vm.changeAutocomplete({ field, value: _vm.formData[field.name] })}},model:{value:(_vm.formData[field.name]),callback:function ($$v) {_vm.$set(_vm.formData, field.name, $$v)},expression:"formData[field.name]"}}):(_vm.showField('dateRange', field))?_c('DateRange',{attrs:{"formData":_vm.formData,"disabled":_vm.disabledField(field),"field":field,"error-messages":_vm.formErrors[field?.name],"label":field.label,"readonly":_vm.readonlyField(field)},model:{value:(_vm.formData[field.name]),callback:function ($$v) {_vm.$set(_vm.formData, field.name, $$v)},expression:"formData[field.name]"}}):_vm._e()],1)}),1),_c('v-divider',{staticClass:"mb-4"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }