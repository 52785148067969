import {
  dateField,
  stringField,
  selectField,
  autocompleteField,
  textareaField,
  datetimeField,
  dropZoneField,
  checkboxField,
  textBlock,
  verticalAutocompleteField,
} from '@/utils/fields.js'
import { stringAction } from '@/utils/actions'
import {
  required,
  hasDate,
  hasTime,
  nameLength,
  minLength,
  numeric,
} from '@/utils/validation.js'
import { v4 as uuidv4 } from 'uuid'

export default {
  path: 'account_foreman_objects',
  id: uuidv4(),
  name: 'Объекты аккаунта',
  type: 'TableDefault',
  active: false,
  config: {
    selector: '#mainTable',
    options: {
      noTableAction: true,
      selecting: false,
      search: {},
      headerFixed: true,
      //url: 'https://dummyjson.com/users',
      url: 'get/pagination/foreman_object_linked',
      urlDetail: 'account_id',
      alias: 'account_id',
      title: 'This is an about page1',
      contextMenu: {
        actions: [
          {
            icon: 'mdi-delete',
            label: 'Удалить объект',
            action: {
              type: 'confirm',
              dialog: {
                text: 'Вы подтверждаете удаление объекта?',
                function: async (context) => {
                  await context.store.dispatch('form/delForm', {
                    url: 'delete/foreman_objects',
                    body: {
                      data: {
                        account_id: parseInt(context.route.params.id),
                        objects: [context.data.row.id],
                      },
                    },
                  })
                },
              },
            },
          },
        ],
      },
      filter: [
        {
          field: 'account_id',
        },
      ],
    },
    panel: {
      buttons: [
        {
          label: 'Обновить',
          class: ['v-table-button--custom'],
          url: '$IconEdit',
          backgroundColor: '#ffffff',
        },
        {
          label: 'Добавить',
          class: ['v-table-button--custom'],
          url: '/add_object',
          type: 'pushUrl',
          backgroundColor: '#fff',
          isShow: {
            condition: [
              {
                funcCondition: (ctx) => {
                  return !!ctx.store.state.user.is_chief
                },
                type: true,
              },
            ],
          },
        },
        // {
        //   label: 'Скачать',
        //   class: ['v-table-button--custom'],
        //   function: consolePanel,
        //   backgroundColor: '#fff',
        // },
      ],
    },
    head: [
      {
        title: 'ID',
        type: 'default',
        align: 'center',
        fixed: {
          value: false,
          position: 'left',
        },
        sorts: [
          {
            type: 'string',
            default: '',
            value: '',
            isShow: false,
          },
        ],
        alias: 'ab.id',
        isShow: true,
        width: '40',
        value: 'id',
        search: {
          field: '',
          isShow: true,
        },
      },
      {
        title: 'Название',
        type: 'default',
        align: 'center',
        fixed: {
          value: false,
          position: 'left',
        },
        sorts: [
          {
            type: 'string',
            default: '',
            value: '',
            isShow: false,
          },
        ],
        alias: 'ab.id',
        isShow: true,
        width: '40',
        value: 'name',
        search: {
          field: '',
          isShow: true,
        },
      },
    ],
    data: {
      rows: [],
      totalRows: null,
      pageLength: 20,
      currentPage: 1,
      totalPages: null,
      footer: null,
    },
    detail: {
      type: 'popup', // String 'popup' or 'page'
      getOnClose: true,
      classes: [''], // List class
      width: '600px',
      method: 'get',
      alias: 'account_bank',
      url: '/get/form/',
      requestId: 'card_id',
      name: 'Добавить объект',
      bootstrapClass: [''], // List class from bootstrap ( col-6, pa-2... )
      activeTab: null,
      tabs: [
        {
          path: 'add_object',
          id: 1,
          name: 'Добавить объект',
          type: 'FormDefault',
          detail: false,
          lists: [],
          alias: 'account_bank',
          active: false,
          fields: [
            verticalAutocompleteField({
              label: 'Объекты',
              name: 'object_id',
              subtype: 'multiple',
              placeholder: '',
              class: [''],
              selectOption: {
                text: 'name',
                value: 'id',
              },
              items: [],
              page: 1,
              search: '',
              url: 'get/pagination_list/foreman_object',
              position: {
                cols: 12,
                sm: 12,
              },
              validations: { required },
              bootstrapClass: [''],
              filter: [
                {
                  field: 'account_id',
                  source: 'route.params.id',
                  type: 'num',
                },
              ],
            }),
          ],
          actions: [
            stringAction({
              text: 'Закрыть',
              type: 'submit',
              color: 'text',
              name: 'closePopup',
              action: 'closePopup',
              to: 'account/:id',
              skipValidation: true,
            }),
            stringAction({
              text: 'Создать',
              type: 'submit',
              module: 'form/create',
              url: 'create/multiple_target',
              name: 'custom',
              action: 'func',
              color: 'primary',
              func: async (ctx) => {
                const requestData = {
                  account_id: parseInt(ctx.context.root.route.params.id),
                  objects: ctx.formData.object_id,
                }
                const data = await ctx.context.root.store.dispatch(
                  'form/create',
                  {
                    url: 'add/foreman_objects',
                    body: {
                      data: requestData,
                    },
                  }
                )
                if (data.code === 1) {
                  return true
                }
                if (data.code === 2) {
                  ctx.store.commit('notifies/showMessage', {
                    color: 'warning',
                    content: 'Попробуйте еще раз...',
                    timeout: 2000,
                  })
                } else if (data.code === 3) {
                  ctx.store.commit('notifies/showMessage', {
                    color: 'error',
                    content: 'Что-то пошло не так...',
                    timeout: 2000,
                  })
                } else if (data.code === 4) {
                  ctx.store.commit('notifies/showMessage', {
                    color: 'error',
                    content: 'Нет доступа',
                    timeout: 2000,
                  })
                }
              },
              skipValidation: true,
            }),
          ],
          formData: {},
        },
      ],
    },
    actions: [
      stringAction({
        text: 'Закрыть',
        type: 'submit',
        color: 'text',
        name: 'closePopup',
        action: 'closePopup',
        to: 'account/:id',
        skipValidation: true,
      }),
    ],
  },
}
