import Vue, {
  watch,
  ref,
  computed,
  onMounted,
  toRef,
  getCurrentInstance,
} from 'vue'
import { useRoute } from 'vue-router/composables'
import { getList } from '@/api/selects'
import _ from 'lodash'
import store from '@/store'
import form from '@/store/modules/form'
import { useRouter } from 'vue-router/composables'

export default {
  name: 'list',
  props: {
    field: {
      type: Object,
      default: () => {},
    },
    value: {
      type: [String, Number, Object, Array],
    },
    formData: {
      type: Object,
    },
    originalData: {
      type: Object,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    fields: {
      type: Object,
    },
    mode: {
      type: String,
    },
    environment: {
      type: Object,
    },
    getDepFilters: {
      type: Function,
    },
    items: {
      type: Array,
      default: null,
    },
    labelIcon: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    const router = useRouter()
    const context = ref({ context: ctx, props, router })

    return { context }
  },
}
