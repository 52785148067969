var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"form"},[_c('v-row',[_c('v-col',{staticClass:"field-col",attrs:{"cols":"12","sm":"3"}},[_c('div',{staticClass:"accountName"},[_c('p',[_vm._v(_vm._s(_vm.account.name))]),(!_vm.formatedRow.includes('undefined'))?_c('p',[_vm._v(_vm._s(_vm.formatedRow))]):_vm._e(),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.thisTarget?.error),expression:"thisTarget?.error"}],staticClass:"accountName-tooltip"},'div',attrs,false),on),[_c('v-icon',{attrs:{"size":"20","color":"red"}},[_vm._v("mdi-alert")])],1)]}}])},[(_vm.thisTarget?.error)?_c('span',[_vm._v(_vm._s(_vm.thisTarget.error))]):_vm._e()])],1)]),_c('v-col',{staticClass:"field-col",attrs:{"sm":_vm.showKey ? 3 : 6}},[_c('Autocomplete',{ref:"autocompleteRef",attrs:{"field":_vm.fieldsTemplate.avatar_with_user_key_id,"error-messages":_vm.formErrors.avatar_with_user_key_id,"formData":_vm.formData},on:{"changeValue":(item) =>
            _vm.changeAutocomplete({
              value: _vm.formData.avatar_with_user_key_id,
              field: _vm.fieldsTemplate.avatar_with_user_key_id,
              item,
            })},model:{value:(_vm.formData.avatar_with_user_key_id),callback:function ($$v) {_vm.$set(_vm.formData, "avatar_with_user_key_id", $$v)},expression:"formData.avatar_with_user_key_id"}})],1),_c('v-col',{staticClass:"field-col",attrs:{"cols":"12","sm":"3"}},[_c('Autocomplete',{ref:"autocompleteRef",attrs:{"field":_vm.fieldsTemplate.vid_vedomost_id_logistic,"error-messages":_vm.formErrors.vid_vedomost_id_logistic,"formData":_vm.formData},on:{"changeValue":(item) =>
            _vm.changeAutocomplete({
              value: _vm.formData.vid_vedomost_id_logistic,
              field: _vm.fieldsTemplate.vid_vedomost_id_logistic,
              item,
            })},model:{value:(_vm.formData.vid_vedomost_id_logistic),callback:function ($$v) {_vm.$set(_vm.formData, "vid_vedomost_id_logistic", $$v)},expression:"formData.vid_vedomost_id_logistic"}})],1),(_vm.showField('select', _vm.fieldsTemplate.print_form_key))?_c('v-col',{staticClass:"field-col",attrs:{"cols":"12","sm":"3"}},[_c('Autocomplete',{ref:"autocompleteRef",attrs:{"field":_vm.fieldsTemplate.print_form_key,"error-messages":_vm.formErrors.print_form_key,"formData":_vm.formData},on:{"changeValue":(item) =>
            _vm.changeAutocomplete({
              value: _vm.formData.print_form_key,
              field: _vm.fieldsTemplate.print_form_key,
              item,
            })},model:{value:(_vm.formData.print_form_key),callback:function ($$v) {_vm.$set(_vm.formData, "print_form_key", $$v)},expression:"formData.print_form_key"}})],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }