import Vue, {
  computed,
  ref,
  onMounted,
  onBeforeUnmount,
  nextTick,
  watch,
} from 'vue'
import { useRouter, useRoute } from 'vue-router/composables'
import useForm from '@/compositions/useForm.js'

import Autocomplete from '@/components/Autocomplete/form'
import store from '@/store'
import useRequest from '@/compositions/useRequest'
import _, { clone } from 'lodash'
import { required } from '@/utils/validation.js'
import {
  selectField,
  autocompleteField,
  //datetimeField,
  textBlock,
} from '@/utils/fields.js'

export default {
  name: 'Form-Rows-Row',
  props: {
    //field: {
    //  type: Object,
    //  default: () => {},
    //},
    formData: {
      type: Object,
      default: () => {},
    },
    formErrors: {
      type: Object,
      default: () => {},
    },
    tab: {
      type: Object,
      default: () => {},
    },
    tabs: {
      type: Array,
      default: () => [],
    },
    row: {
      type: [String, Object],
    },
    error: {
      type: String,
      default: '',
    },
    //loading: {
    //  type: Boolean,
    //  default: false,
    //},
    activeTab: {
      type: Number,
      default: null,
    },
    target: {
      type: [String, Object],
    },
    entry: {
      type: Object,
    },
    prevTab: {
      type: Object,
    },
    childFormData: {
      type: Array,
    },
  },
  components: {
    Autocomplete,
  },
  setup(props, ctx) {
    const { emit } = ctx
    const route = useRoute()
    const router = useRouter()
    const autocompleteRef = ref(null)
    let rowIndex = 0
    const context = {
      root: {
        store,
        router,
        ctx,
        route,
      },
    }
    const loading = ref(true)
    const { alias } = props.tab

    const fieldsRef = ref(null)

    const isEdit = computed(() => {
      if (props.tab.routeParam) {
        if (
          props.tab.routeParam &&
          !route.params[props.tab.routeParam] &&
          ['change-personal'].includes(props.tab.path)
        ) {
          return route.params['id'] ? 'edit' : 'add'
        }
        return route.params[props.tab.routeParam] ? 'edit' : 'add'
        // return 'add'
      } else {
        return route.params.id ? 'edit' : 'add'
      }
    })
    // const fields = () => {
    //   const fields = {}
    //   const tabFields = {}
    //   for (let i = 0; i < props.tab.fields.length; i++) {
    //     tabFields[props.tab.fields[i].name] = props.tab.fields[i]
    //   }
    //   for (let key in tabFields) {
    //     const { validations } = tabFields[key]
    //     Vue.set(fields, tabFields[key].name, {})
    //     Vue.set(fields[tabFields[key].name], 'validations', validations)
    //     Vue.set(fields[tabFields[key].name], 'default', tabFields[key].value)
    //   }
    //   return fields
    // }

    const params = props.tab.lists

    const enterHandler = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault()
      }
    }
    // const data = params

    const getRequestParam = () => {
      if (props.detail?.requestId) {
        return _.get(route.params, props.detail.requestId)
      } else if (route.params.id) {
        return route.params.id
      }
    }
    const { makeRequest } = useRequest({
      context,
      request: async () => {
        const data = await store.dispatch(
          'form/get',
          `get/form/${alias}/${getRequestParam()}`
        )
        return data
      },
    })
    const { makeRequest: makeRequestList } = useRequest({
      context,
      request: (data) => store.dispatch('list/get', data),
    })
    const { makeRequest: changeForm } = useRequest({
      context,
      successMessage: params?.successMessage === false ? false : 'Сохранено',
      request: (params) => {
        let routeParam
        if (params.action?.useRouteParam) {
          routeParam = params.action.useRouteParam
        } else {
          routeParam = 'id'
        }
        return store.dispatch(params.module, {
          url: params.url,
          body: { data: { id: +route.params[routeParam], ...params.formData } },
        })
      },
    })
    const { makeRequest: changeFormId } = useRequest({
      context,
      successMessage: params?.successMessage === false ? false : 'Сохранено',
      request: (params) => {
        let id
        if (props.tab.routeParam) {
          id = route.params[props.tab.routeParam] ?? route.params.id
        } else {
          id = route.params.id
        }
        return store.dispatch(params.module, {
          url: params.url + '/' + id,
          body: { data: { ...params.formData } },
        })
      },
    })
    const { makeRequest: createForm } = useRequest({
      context,
      successMessage: params?.successMessage === false ? false : 'Сохранено',
      request: (params) => {
        return store.dispatch(params.module, {
          url: params.url,
          body: {
            data: params.formData ? params.formData : formData,
          },
        })
      },
    })

    const { makeRequest: deleteFormById } = useRequest({
      context,
      successMessage: 'Удалено!',
      request(params) {
        const req = store.dispatch(params.module, {
          url: params.url,
          id: route.params.id,
        })
        return req
      },
    })

    if (props.tab.hasOwnProperty('content')) {
      props.tab.fields[0].items[0].id = props.content.account_id
      props.tab.fields[0].items[0].name = props.content.account_name
      props.tab.fields[0].value = props.content.account_id
      props.tab.fields[2].value = Number(props.content.hour)
      props.tab.fields[1].value = props.content.date
      props.tab.fields[4].value = props.content.date.slice(0, -3)
      if (props.content.id) {
        props.tab.fields[6].value = props.content?.id
      }
    }
    const closePopupForm = (double) => {
      router.push({ name: route.matched.at(-2).name })
      popupForm.value.isShow = false
      if (double) {
        emit('closePopup')
        emit('getItems')
      }
    }

    const downloadFile = (link) => {
      Vue.downloadFile(link)
    }

    const getItems = () => {
      const refreshField = props.tab.fields.find((x) => {
        if (x.appendAction) {
          return x.appendAction.find(
            (y) => y.action.name === route.name && y.action.refresh
          )
        }
      })
      const refreshFullForm = props.tab.fields.find((x) => {
        if (x.appendAction) {
          return x.appendAction.find(
            (y) => y.action.name === route.name && y.action.refreshForm
          )
        }
      })
      if (refreshField) refreshSelectItems(refreshField)
      if (refreshFullForm) refreshForm()
    }
    const {
      formData,
      validate,
      formErrors,
      vForm,
      touchedForm,
      clickHandler,
      getData,
      changeAutocomplete,
      changeSelect,
      changeValue,
      showField,
      refreshSelectItems,
      refreshForm,
      openMenu,
      disabledField,
      hideField,
      changeCheckbox,
      readonlyField,
      refreshTable,
      isHideBtn,
      colsField,
      appendFieldHandler,
      popupForm,
      appendActionShow,
      isRequired,
      fields,
      rowsArray,
      emitFormData,
      environment,
      addFiles,
      originalData,
      entityData,
      getDepFilters,
      changeMonth,
      customFunc,
    } = useForm({
      form: props.tab,
      tableComp: props.tableComp,
      context,
      detail: props.detail,
      loading,
      prevTab: props.prevTab,
      // fields: fields(),
      // setFields: fields,
      makeRequest,
      makeRequestList,
      isEdit,
      changeForm,
      mode: isEdit.value,
      createForm,
      deleteFormById,
      changeFormId,
      formDataParent: props.formDataParent,
    })

    const getAppendInfo = (method) => {
      return method({ formData })
    }

    onMounted(async () => {
      if (props.entry) {
        const property = Object.entries(props.entry)[1]
        formData[property[0]] = property[1]
        if (props.tab?.orderByFromLastTab?.pasteIdInField) {
          formData[props.tab?.orderByFromLastTab?.pasteIdInField] =
            props.entry.id
        }
        rowIndex = props.childFormData.push(formData) - 1
      }

      watch(
        () => formData,
        () => {
          props.childFormData[rowIndex] = formData
          ctx.emit('formUpdate', formData)
        },
        { deep: true }
      )

      document.addEventListener('keypress', enterHandler)
      props.tab.fields.forEach((item) => {
        if (item.items) item.items = []
      })
      await getData()
    })

    onBeforeUnmount(() => {
      document.removeEventListener('keypress', enterHandler)
    })

    return {
      readonlyField,
      //endIntersect,
      formData,
      validate,
      //$errors,
      vForm,
      touchedForm,
      formErrors,
      getData,
      loading,
      showField,
      autocompleteRef,
      changeAutocomplete,
      changeSelect,
      openMenu,
      clickHandler,
      isEdit,
      disabledField,
      hideField,
      customFunc,
      changeCheckbox,
      refreshTable,
      isHideBtn,
      route,
      colsField,
      enterHandler,
      appendFieldHandler,
      popupForm,
      closePopupForm,
      appendActionShow,
      getItems,
      fieldsRef,
      getAppendInfo,
      downloadFile,
      changeValue,
      isRequired,
      fields,
      mode: isEdit.value,
      emitFormData,
      environment,
      addFiles,
      originalData,
      entityData,
      getDepFilters,
      changeMonth,
    }
  },
}
