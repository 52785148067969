var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:['doljnostItem', !_vm.dense && 'pa-6'],style:({
    width: _vm.dense ? '100%' : _vm.styleType === 1 ? '567px' : '500px',
  })},[(!_vm.dense)?_c('div',{staticClass:"text--text font-weight-700 text-cut"},[_c('span',{attrs:{"title":_vm.data.name}},[_vm._v(" "+_vm._s(_vm.data.name)+" ")])]):_vm._e(),(!_vm.dense)?_c('v-divider',{staticClass:"my-3"}):_vm._e(),(_vm.loading)?[_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-progress-circular',{attrs:{"color":"primary","size":56,"indeterminate":""}})],1)]:(!_vm.doljnost)?[_c('div',{staticClass:"flex-grow-1 d-flex justify-center align-center"},[_c('plug',{attrs:{"text":"Данные не найдены"}})],1)]:[(_vm.styleType === 1)?[(_vm.allowedPermission && _vm.doljnost.doljnost_coefficient)?_c('Coefficient',{attrs:{"requestData":{
          entity: 'doljnost',
          id: _vm.data.id,
          prefix: _vm.prefix,
          restData: {
            [_vm.parent.entity ? `${_vm.parent.entity}_id` : undefined]:
              _vm.parent.data?.id,
          },
        },"coef":{
          1: _vm.doljnost.doljnost_coefficient[1].coefficient,
          2: _vm.doljnost.doljnost_coefficient[2].coefficient,
        }},on:{"refresh":function($event){return _vm.getData()}}}):_vm._e(),(_vm.allowedPermission && _vm.doljnost.contract_data)?_c('div',[_c('div',{staticClass:"text--text font-weight-700 mb-2"},[_vm._v("Договор")]),_c('v-row',{staticClass:"justify-space-between align-center"},[_c('div',{staticClass:"mb-2 mr-2"},[_c('v-icon',{attrs:{"color":"textGray","size":"24"}},[_vm._v("mdi-clipboard-text-outline")]),_c('span',{staticClass:"ml-2 text--text font-weight-500"},[_vm._v(_vm._s(_vm.convertData(_vm.doljnost.contract_data.date_from))+" — "+_vm._s(_vm.convertData(_vm.doljnost.contract_data.date_to)))])],1),_c('div',{staticClass:"mb-2"},[_c('v-btn',{attrs:{"color":"#EDF5FD","elevation":"0"},on:{"click":function($event){return _vm.download(_vm.doljnost.contract_data.file)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary","size":"24"}},[_vm._v("mdi-file-download-outline")]),_c('span',{staticClass:"primary--text"},[_vm._v("Скачать")])],1),_c('historyChange',{attrs:{"id":_vm.doljnost.external_tariff_data.id},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',{staticClass:"ml-2",attrs:{"color":"#EDF5FD","elevation":"0"},on:{"click":on}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary","size":"24"}},[_vm._v("mdi-cog-clockwise")]),_c('span',{staticClass:"primary--text"},[_vm._v("Изменения")])],1)]}}],null,false,3548857359)})],1)])],1):_vm._e(),_vm._l((_vm.DOLJNOST_STRUCTURE),function(item,index){return (_vm.tariff[index] && ((_vm.allowedPermission && !index) || index))?_c('div',{key:index,staticClass:"rate"},[(item.title)?_c('div',{staticClass:"text--text font-weight-700 mb-2"},[_vm._v(" "+_vm._s(item.title)+" ")]):_vm._e(),_c('div',{staticClass:"pt-3 pb-1 px-3",staticStyle:{"background-color":"var(--v-pageBackground-base)","border-radius":"8px"}},[(item.subtitle)?_c('div',{staticClass:"w-100 text-center textGray--text font-size-14"},[_vm._v(" "+_vm._s(item.subtitle)+" ")]):_vm._e(),_c('div',{class:[
              'overflow-auto',
              'd-flex',
              'pb-2',
              'justify-space-between',
            ]},[_vm._l((_vm.RATE_STRUCTURE),function(rate){return _c('div',{key:rate.key,staticClass:"rateItem"},[_c('div',{staticClass:"text--text mb-2"},[_vm._v(_vm._s(rate.title))]),_c('div',{staticClass:"px-2 py-1 primary--text font-weight-700 text-cut tag",attrs:{"title":_vm.tariff[index][rate.key] ? _vm.tariff[index][rate.key] : '-'}},[_vm._v(" "+_vm._s(_vm.tariff[index][rate.key] ? _vm.tariff[index][rate.key] : '-')+" ")])])}),(item.individual)?_c('div',{class:['rateItem']},[_c('div',{staticClass:"text--text mb-2"},[_vm._v("Инд.")]),_c('ChangeMenu',{attrs:{"entity":"tariff","activatorDisabled":!_vm.allowedPermission,"val":_vm.tariff[index].sum,"requestData":{
                  entity: 'tariff',
                  id: _vm.tariff[index].tariff_id,
                  price_id: _vm.tariff[index].price_id,
                },"single":index},on:{"refresh":function($event){return _vm.getData()}}})],1):_vm._e()],2)]),(_vm.allowedPermission && item.coef)?_c('div',{staticClass:"mt-2"},[_c('Coefficient',{attrs:{"tooltip":`${_vm.convertData(
              _vm.tariff[index]?.date_from
            )} - ${_vm.convertData(_vm.tariff[index]?.date_to)} `,"coef":{
              [index]: _vm.tariff[index]?.coefficient,
            },"requestData":{
              entity: 'tariff',
              id: _vm.doljnost.external_tariff_data.id,
            },"single":index},on:{"refresh":function($event){return _vm.getData()}}})],1):_vm._e()]):_vm._e()})]:(_vm.styleType === 2)?[_c('v-row',{staticClass:"justify-space-between"},[_c('span',{staticClass:"secondary--text font-weight-700"},[_vm._v("Тариф")]),_c('historyChange',{attrs:{"id":_vm.doljnost.external_tariff_data.id},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',{staticClass:"ml-2",attrs:{"color":"#EDF5FD","elevation":"0"},on:{"click":on}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary","size":"24"}},[_vm._v("mdi-cog-clockwise")]),_c('span',{staticClass:"primary--text"},[_vm._v("Изменения")])],1)]}}])})],1),_c('v-row',{staticClass:"pa-3 d-block",staticStyle:{"background-color":"var(--v-pageBackground-base)","border-radius":"8px"}},[_c('div',{staticClass:"textGray--text line-normal"},[_vm._v("Внешняя ставка")]),_c('div',{staticClass:"mt-1 secondary--text line-normal"},[_vm._v("База, ₽")]),_c('div',{staticClass:"mt-2 secondary--text font-weight-700 font-size-24 line-normal"},[_vm._v(" "+_vm._s(_vm.doljnost.external_tariff_data.base_rate)+" ")])]),_c('v-row',_vm._l((_vm.DOLJNOST_STRUCTURE),function(item,index){return (item.subtitle)?_c('div',{key:index,staticClass:"flex-grow-1 pa-3 rateItem tariffItem",staticStyle:{"background-color":"var(--v-pageBackground-base)","border-radius":"8px"}},[_c('div',{staticClass:"textGray--text line-normal"},[_vm._v("Внутренняя ставка")]),_c('div',{class:['mt-1', 'secondary--text', 'line-normal']},[_vm._v(" "+_vm._s(item.subtitle)+", ₽ ")]),_c('div',{class:[
              'mt-2',
              _vm.tariff[index].sum ? 'primary--text' : 'secondary--text',
              'font-weight-700',
              'font-size-24',
              'line-normal',
            ]},[_vm._v(" "+_vm._s(_vm.tariff[index].sum ?? _vm.tariff[index].base_rate)+" "),(_vm.tariff[index].sum)?_c('span',{staticClass:"font-weight-400 font-size-14",staticStyle:{"vertical-align":"top"}},[_vm._v("Инд.")]):_vm._e()]),_c('div',{staticClass:"mt-2"},[_c('ChangeMenu',{attrs:{"entity":"tariff","requestData":{
                entity: 'tariff',
                id: _vm.tariff[index].tariff_id,
                price_id: _vm.tariff[index].price_id,
              },"val":_vm.tariff[index].sum,"single":index,"styleType":2},on:{"refresh":function($event){return _vm.getData()}}})],1),_c('div',{staticClass:"mt-2"},[_c('Coefficient',{attrs:{"tooltip":`${_vm.convertData(
                _vm.tariff[index]?.date_from
              )} - ${_vm.convertData(_vm.tariff[index]?.date_to)} `,"coef":{
                [index]: _vm.tariff[index]?.coefficient,
              },"requestData":{
                entity: 'tariff',
                id: _vm.doljnost.external_tariff_data.id,
              },"single":index,"short":""},on:{"refresh":function($event){return _vm.getData()}}})],1)]):_vm._e()}),0)]:_vm._e()]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }