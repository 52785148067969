import {
  dateField,
  stringField,
  selectField,
  autocompleteField,
  textareaField,
  datetimeField,
  dropZoneField,
  checkboxField,
  colorPicker,
  textBlock,
} from '@/utils/fields.js'
import { isDirector } from '@/utils/permissions'
import { stringAction } from '@/utils/actions'
import { required, hasDate, hasTime, nameLength } from '@/utils/validation.js'
import { v4 as uuidv4 } from 'uuid'

export default {
  id: uuidv4(),
  path: 'edit',
  name: 'Тарифы',
  type: 'FormRates',
  detail: true,
  alias: 'object',
  active: false,
  isShow: {
    value: true,
    condition: [
      {
        funcCondition: (ctx) => {
          if (
            [1, 2, 3, 4, 8, 9, 15, 17].includes(
              ctx.store.state.user.permission_id
            )
          ) {
            if (
              ctx.mainData.direction_json?.includes(2) &&
              ![1, 2, 3, 12].includes(ctx.mainData.type)
            )
              return true
            if (
              ctx.mainData.direction_json?.includes(1) ||
              ctx.mainData.direction_json?.includes(6) ||
              ctx.mainData.direction_json?.includes(7)
            )
              return true
          }
          return false
        },
      },
    ],
  },
  actions: [
    stringAction({
      text: 'Сохранить',
      type: 'submit',
      module: '',
      name: 'saveForm',
      nextForm: true,
    }),
  ],
}
